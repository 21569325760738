// import React from "react";

// function XGConsoleWebView() {
//   return (
//     <div>
//       <h1>Web View Example</h1>
//       <iframe src="https://pg9win.xgaming.io/" />
//     </div>
//   );
// }

// export default XGConsoleWebView;

// import { useState } from "react";

// import Pusher from "pusher-js";
/* eslint-disable */
// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function XGConsoleWebView(props) {
  console.log("XGConsoleWebView-props", props);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <iframe src="https://pg9win.xagent.live" style={{ width: "100%", height: "100vh" }}></iframe>
    </DashboardLayout>
  );
}

export default XGConsoleWebView;
