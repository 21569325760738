/* eslint-disable */
import { useState, useEffect } from "react";

import { format } from "date-fns";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiButton from "components/SuiButton";
import { ResponseAlert } from "components/ResponseAlert";
import SuiInput from "components/SuiInput";
// sweetalert2 components
// import Swal from "sweetalert2";

// Soft UI Dashboard PRO React example components
import ReactExport from "react-export-excel";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { th } from "date-fns/locale";
import { ReportSummary } from "api/report";
import { formatter } from "utils/validations";
import excelICON from "../../assets/png/excel.png";

const columns = [
  { Header: "ยูสเซอร์", accessor: "member_username" },
  { Header: "กระเป๋าหลัก", accessor: "credit_balance" },
  { Header: "ฝาก", accessor: "sum_deposit" },
  { Header: "ถอน", accessor: "sum_withdraw" },
  { Header: "กำไร/ขาดทุน", accessor: "summary" },
];

function SummaryTable() {
  const [summaryTableData, setSummaryTableData] = useState({ columns, rows: [] });
  const [isLoading, setLoading] = useState(false);
  const [totalDeposit, setTotalDeposit] = useState(0);
  const [totalWithdraw, setTotalWithdraw] = useState(0);
  const [totalSummary, setTotalSummary] = useState(0);
  const [startDate, setStartDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [isPicker, setPicker] = useState(false);
  const [excel, setExcel] = useState();
  const [keys, setKeys] = useState();

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  async function handleDownloadMembers(arrayList) {
    console.log("summary-table - handleDownloadMembers - arrayList", arrayList);
    const newExcel = await arrayList.reduce((ret, v) => {
      const sum = {
        username: v?.member_username,
        balance: v?.credit_balance,
        deposit: v?.sum_deposit,
        withdraw: v?.sum_withdraw,
        summary: v?.summary,
      };
      ret.push(sum);
      return ret;
    }, []);
    const headerKeys = newExcel && newExcel.length > 0 ? Object.keys(newExcel[0]) : [];

    setExcel(newExcel);
    setKeys(headerKeys);
  }

  async function getSummary() {
    const createdAt = {};
    if (startDate) {
      createdAt.from = startDate;
    }
    if (endDate) {
      createdAt.to = endDate;
    }
    console.log("createdAt", createdAt);
    setLoading(true);
    try {
      const response = await ReportSummary({ status_code: "success", createdAt });
      console.log("ReportSummary-response", response);
      setLoading(false);
      const status = response?.data?.error;
      if (status === 0) {
        const s = response?.data?.data?.result;
        let tDeposit = 0;
        let tWithdraw = 0;
        const newSum = s.reduce((a, b) => {
          tDeposit += b?.sum_deposit ? parseFloat(b?.sum_deposit) : 0;
          tWithdraw += b?.sum_withdraw ? parseFloat(b?.sum_withdraw) : 0;
          const news = { ...b };
          news.credit_balance = (
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="dark">
              {formatter.format(b?.credit_balance).toString()}
              {" ฿"}
            </SuiTypography>
          );
          news.sum_deposit = (
            <SuiTypography
              component="label"
              variant="caption"
              fontWeight="bold"
              textColor="success"
            >
              {formatter.format(b?.sum_deposit).toString()}
            </SuiTypography>
          );
          news.sum_withdraw = (
            <SuiTypography component="label" variant="caption" fontWeight="bold" textColor="error">
              {formatter.format(b?.sum_withdraw).toString()}
            </SuiTypography>
          );
          if (parseFloat(b?.summary) > 0) {
            news.summary = (
              <SuiTypography
                component="label"
                variant="body2"
                fontWeight="bold"
                textColor="success"
              >
                {formatter.format(b?.summary).toString()}
              </SuiTypography>
            );
          }
          if (parseFloat(b?.summary) < 0) {
            news.summary = (
              <SuiTypography component="label" variant="body2" fontWeight="bold" textColor="error">
                {formatter.format(b?.summary).toString()}
              </SuiTypography>
            );
          }
          a.push(news);
          return a;
        }, []);
        console.log("SummaryTable-newSum", newSum);
        setSummaryTableData({ columns, rows: newSum });
        setTotalDeposit(tDeposit);
        setTotalWithdraw(tWithdraw);
        const summ = tDeposit - tWithdraw;
        setTotalSummary(summ);
        handleDownloadMembers(s);
        return;
      }
      ResponseAlert(response);
    } catch (err) {
      console.log("SummaryTable-getSummary-catch-err", err.response);
      setLoading(false);
      ResponseAlert(err?.response);
    }
  }

  function handleSelectDateRange(v) {
    console.log("member-filter -> handleSelectDateRange - v", v);
    setStartDate(format(new Date(v?.selection?.startDate), "yyyy-MM-dd"));
    setEndDate(format(new Date(v?.selection?.endDate), "yyyy-MM-dd"));
  }

  useEffect(() => {
    getSummary();
  }, []);

  console.log("startDate-endDate", startDate, endDate);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SuiBox pt={6} pb={3}>
        <SuiBox p={3} backgroundColor="white" sx={{ borderRadius: 4 }}>
          {!isLoading && (
            <SuiBox pt={3} pr={3} display="flex" justifyContent="flex-end">
              {user?.role_code === "owner" ? (
                <>
                  {excel && excel.length > 0 && keys && keys.length > 0 && (
                    <ExcelFile
                      filename={`summary_${format(new Date(), "yyyy-MM-dd_HH:mm")}`}
                      element={
                        <SuiButton variant="gradient" buttonColor="info" size="small">
                          ดาวน์โหลด
                        </SuiButton>
                      }
                    >
                      <ExcelSheet data={excel} name="deposit">
                        {keys.map((k) => (
                          <ExcelColumn label={k} value={k} />
                        ))}
                      </ExcelSheet>
                    </ExcelFile>
                  )}
                </>
              ) : (
                <></>
              )}
            </SuiBox>
          )}
          <SuiBox p={3} lineHeight={1} display="flex" alignItems="center">
            <SuiBox component="img" src={excelICON} width="56px" />
            <SuiTypography ml={2} variant="h3" textTransform="capitalize" fontWeight="bold">
              รายงานสรุปยอด
            </SuiTypography>
          </SuiBox>

          <Grid container spacing={2} pl={3} pr={3}>
            <Grid item xs={12} xl={3}>
              {isPicker && (
                <SuiBox
                  position="absolute"
                  customClass="object-cover object-center"
                  zIndex={99}
                  mt={3}
                  ml={3}
                  borderRadius="50"
                  backgroundColor="white"
                >
                  <DateRangePicker
                    locale={th}
                    ranges={[
                      {
                        startDate: new Date(startDate),
                        endDate: new Date(endDate),
                        key: "selection",
                      },
                    ]}
                    onChange={handleSelectDateRange}
                  />
                  <SuiBox display="flex" justifyContent="flex-end" backgroundColor="white" p={2}>
                    <SuiButton
                      variant="gradient"
                      buttonColor="info"
                      size="small"
                      onClick={() => setPicker(false)}
                    >
                      ตกลง
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              )}
              <SuiBox
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
                height="100%"
              >
                <SuiBox lineHeight={0} display="inline-block">
                  <SuiTypography component="label" variant="caption" fontWeight="bold">
                    ช่วงเวลา
                  </SuiTypography>
                </SuiBox>
                <SuiBox onClick={() => setPicker(true)}>
                  <SuiInput
                    value={`${format(new Date(startDate), "dd-MM-yyyy", {
                      locale: th,
                    })} ถึง ${format(new Date(endDate), "dd-MM-yyyy", { locale: th })}`}
                    disabled={true}
                  />
                </SuiBox>
              </SuiBox>
            </Grid>

            <Grid item xs={12} xl={3}>
              <SuiBox display="flex" flexDirection="column" justifyContent="flex-end" height="100%">
                <SuiBox lineHeight={0} display="inline-block">
                  <SuiButton variant="gradient" buttonColor="info" onClick={() => getSummary()}>
                    <Icon className=" font-bold">search</Icon>&nbsp; ค้นหา
                  </SuiButton>
                </SuiBox>
              </SuiBox>
            </Grid>
          </Grid>
        </SuiBox>
        <Grid container spacing={2} pl={3} pr={3}>
          <Grid item xs={12} xl={3}>
            <Card sx={{ margin: 2, padding: 2 }}>
              <SuiBox display="flex" flexDirection="column" textAlign="center">
                <SuiTypography variant="h5" fontWeight="bold" textColor="success">
                  {formatter.format(totalDeposit).toString()}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  textColor="text"
                  textTransform="capitalize"
                  fontWeight="medium"
                  mt={1}
                >
                  ฝาก
                </SuiTypography>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Card sx={{ margin: 2, padding: 2 }}>
              <SuiBox display="flex" flexDirection="column" textAlign="center">
                <SuiTypography variant="h5" fontWeight="bold" textColor="error">
                  {formatter.format(totalWithdraw).toString()}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  textColor="text"
                  textTransform="capitalize"
                  fontWeight="medium"
                  mt={1}
                >
                  ถอน
                </SuiTypography>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Card sx={{ margin: 2, padding: 2 }}>
              <SuiBox display="flex" flexDirection="column" textAlign="center">
                <SuiTypography
                  variant="h5"
                  fontWeight="bold"
                  textColor={totalSummary >= 0 ? "success" : "error"}
                >
                  {formatter.format(totalSummary).toString()}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  textColor="text"
                  textTransform="capitalize"
                  fontWeight="medium"
                  mt={1}
                >
                  กำไร/ขาดทุน
                </SuiTypography>
              </SuiBox>
            </Card>
          </Grid>
          <Grid item xs={12} xl={3}>
            <Card sx={{ margin: 2, padding: 2 }}>
              <SuiBox display="flex" flexDirection="column" textAlign="center">
                <SuiTypography variant="h5" fontWeight="bold" textColor="info">
                  {summaryTableData && summaryTableData.rows.length}
                </SuiTypography>
                <SuiTypography
                  variant="body2"
                  textColor="text"
                  textTransform="capitalize"
                  fontWeight="medium"
                  mt={1}
                >
                  จำนวนผู้เล่น
                </SuiTypography>
              </SuiBox>
            </Card>
          </Grid>
        </Grid>
        {isLoading ? (
          <SuiBox mb={1} mt={3} lineHeight={0} display="inline-block">
            <LinearProgress color="secondary" />
          </SuiBox>
        ) : (
          <DataTable table={summaryTableData} canSearch />
        )}
      </SuiBox>
    </DashboardLayout>
  );
}

export default SummaryTable;
